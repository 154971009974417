import React, { useState } from 'react';
import SEO from '../../components/seo';
import Layout from '../../layouts/DefaultLayout';
import CMSContent from '../../components/CMSContent';
import FAQEntryList from '../../components/FAQEntryList';
import Modal from '../../components/Modal';

import ContactUs from '../../components/ContactUs';
import ShareReviewsForm from './components/ShareReviewsForm';
import { graphql } from 'gatsby';


const ShareReviews = ({ pageContext, location, ...props }) => {
  const { data } = props;
  const { sitePage } = data.bhip;
  const properties = data?.bhip?.properties || null;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [contactUsModalIsOpen, setContactUsModalIsOpen] = useState(false);
  const [token, setToken] = useState(null);
  return (
    <Layout searchbarLabel={sitePage.title}>
      <SEO
        title={sitePage.seoTitle}
        description={sitePage.seoDescription}
        featuredImage={sitePage.featuredImage}
        headTags={sitePage.headTags}
      />
      <div className="container mt-3">
        <div className="mb-3">
          <CMSContent blockData={sitePage.cmsContent.blockData}>
            {sitePage.cmsContent.html}
          </CMSContent>
        </div>

        <ShareReviewsForm
          properties={properties}
          onSuccess={() => setModalIsOpen(true)}
        />

        <div className="mt-5">
          <h2>Become a travel inspiration</h2>
          <p>Your journey could inspire countless others. Share your experience and become a beacon for fellow travelers seeking their next destination.</p>
          <div className="d-flex gap-3">
            <a href="https://g.page/r/CUmWUvzHkVzOEBM/review" className="btn btn-outline-primary d-flex align-items-center mr-2">
              <img src="/google-my-business.svg" alt="Google" className="mr-1plus" width="20" height="20" />
              Review us on Google
            </a>
            <a href="https://www.facebook.com/BeachHousesinParadise/reviews" className="btn btn-outline-primary d-flex align-items-center">
              <img src="/facebook.svg" alt="Facebook" className="mr-1plus" width="20" height="20" />
              Review us on Facebook
            </a>
          </div>
        </div>

        <div className="mt-5">
          <h2>Your Experience Was Not Great?</h2>
          <p>Share your concerns directly with the property manager:</p>
          <button onClick={() => setContactUsModalIsOpen(true)} className="btn btn-outline-primary d-flex align-items-center" style={{ width: 'fit-content' }}>
            <img src="/contact.svg" alt="Contact" className="mr-1plus" width="20" height="20" />
            Contact us Directly
          </button>
          <Modal
            expanded={true}
            isOpen={contactUsModalIsOpen}
            title={'Contact Us'}
            onClose={() => setContactUsModalIsOpen(false)}
          >
            <ContactUs onClose={() => setContactUsModalIsOpen(false)} />
          </Modal>
        </div>
      </div>
      {modalIsOpen && (
        <Modal
          disableFocusTrap={true}
          isOpen={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          expanded={true}
          title={
            <span style={{ fontSize: '20px' }}>
              Thank you for sharing your experience with us!
            </span>
          }
        >
          <p>Your review has been submitted successfully.</p>
        </Modal>
      )}
    </Layout>
  );
};
export const query = graphql`
  query ShareReviews($slug: String) {
    bhip {
      sitePage(where: { slug: $slug }) {
        ...SitePageData
      }
      properties {
        id
        name
      }
    }
  }
`;

export default ShareReviews;
