import React, { Component } from 'react';
import HeaderMenu from '../../components/HeaderMenu';
import PropertySearchbarHeader from '../../components/PropertySearchbarHeader';
import { Modal as LastMinuteDealsModal } from '../../components/LastMinuteDeals';
import Footer from '../../components/Footer';

class PropertySearchLayout extends Component {
  render() {
    const { children, showFooter, subheader } = this.props;

    return (
      <>
        <HeaderMenu />
        <div className={`bg-primary`}>
          <div className="container">
            <div className="row">
              <div className="col">
                {subheader && (
                  <PropertySearchbarHeader
                    includeOtherSearchParams={true}
                    pageName="search-page"
                    {...this.props}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={`sticky-row bg-white sticky-shadow`}>
          {this.props.stickyRow && this.props.stickyRow()}
        </div>
        <main>{children}</main>
        {showFooter && <Footer />}
      </>
    );
  }
}

PropertySearchLayout.defaultProps = {
  footer: true,
  subheader: true,
};

export default PropertySearchLayout;
