import React, { useState } from 'react';
import ReactStars from "react-rating-stars-component";
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import './styles.scss';

const CREATE_ANONYMOUS_REVIEW = gql`
  mutation createAnonymousReview($data: AnonymousReviewCreateInput!) {
    createAnonymousReview(data: $data) {
      id      
    }
  }
`;

const ShareReviewsForm = ({ properties, onSuccess }) => {
    const [createAnonymousReview, { loading: submitting }] = useMutation(CREATE_ANONYMOUS_REVIEW);
    const [formData, setFormData] = useState({
        propertyId: '',
        fullName: '',
        checkInDate: '',
        checkOutDate: '',
        overallRating: 0,
        cleanliness: 0,
        staffService: 0,
        propertyCondition: 0,
        areaRating: 0,
        reviewTitle: '',
        reviewContent: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleRatingChange = (name) => (newRating) => {
        setFormData(prev => ({
            ...prev,
            [name]: newRating
        }));
    };

    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Log the form data
        console.log('Form submission data:', formData);

        // Validate required fields
        if (!formData.overallRating || formData.overallRating === 0) {
            setError('Overall Rating is required');
            return;
        }

        // Validate check-in and check-out dates
        if (formData.checkInDate && formData.checkOutDate) {
            const checkInDate = new Date(formData.checkInDate);
            const checkOutDate = new Date(formData.checkOutDate);

            if (checkOutDate <= checkInDate) {
                setError('Check-out date must be after check-in date');
                return;
            }
        }

        try {
            setError(''); // Clear any previous errors

            // Prepare the data for the mutation
            const mutationData = {
                propertyId: parseInt(formData.propertyId),
                name: formData.fullName,
                reviewTitle: formData.reviewTitle,
                reviewContent: formData.reviewContent,
                overallRating: parseInt(formData.overallRating),
                cleanliness: formData.cleanliness ? parseInt(formData.cleanliness) : null,
                staffService: formData.staffService ? parseInt(formData.staffService) : null,
                propertyCondition: formData.propertyCondition ? parseInt(formData.propertyCondition) : null,
                areaRating: formData.areaRating ? parseInt(formData.areaRating) : null,
                checkInDate: formData.checkInDate,
                checkOutDate: formData.checkOutDate,
                reference: null
            };

            console.log('Mutation data:', mutationData);

            // Call the mutation
            const response = await createAnonymousReview({
                variables: {
                    data: mutationData
                }
            });

            console.log('Mutation response:', response);

            // Call onSuccess callback if provided
            if (onSuccess) {
                onSuccess();
            }
        } catch (error) {
            console.error('Error submitting review:', error);
            setError('Error submitting review. Please try again.');
        }
    };

    const starProps = {
        size: 42,
        activeColor: "#ffd700",
        color: "#ddd",
        isHalf: false,
    };

    return (
        <form onSubmit={handleSubmit} className="share-review-form">
            <p className="form-description">
                Please take a moment to share your experience about your stay at Beach Houses in Paradise. We'd love to hear what you have to say.
            </p>

            <div className="form-group">
                <label>Property <span className="required">*</span></label>
                <select
                    name="propertyId"
                    value={formData.propertyId}
                    onChange={handleInputChange}
                    required
                >
                    <option value="">Select a property</option>
                    {properties?.map(property => (
                        <option key={property.id} value={property.id}>
                            {property.name}
                        </option>
                    ))}
                </select>
            </div>

            <div className="form-group">
                <label>Name</label>
                <input
                    type="text"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleInputChange}
                    placeholder="Jane Doe"
                />
            </div>

            <div className="form-row">
                <div className="form-group">
                    <label>Check-In Date</label>
                    <input
                        type="date"
                        name="checkInDate"
                        value={formData.checkInDate}
                        onChange={handleInputChange}
                    />
                </div>

                <div className="form-group">
                    <label>Check-Out Date</label>
                    <input
                        type="date"
                        name="checkOutDate"
                        value={formData.checkOutDate}
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <div className="rating-section">
                <div className="rating-group">
                    <label>Overall Rating <span className="required">*</span></label>
                    <ReactStars
                        {...starProps}
                        onChange={handleRatingChange('overallRating')}
                        value={formData.overallRating}
                    />
                </div>

                <div className="rating-group">
                    <label>Cleanliness</label>
                    <ReactStars
                        {...starProps}
                        onChange={handleRatingChange('cleanliness')}
                        value={formData.cleanliness}
                    />
                </div>

                <div className="rating-group">
                    <label>Staff & Service</label>
                    <ReactStars
                        {...starProps}
                        onChange={handleRatingChange('staffService')}
                        value={formData.staffService}
                    />
                </div>

                <div className="rating-group">
                    <label>Property conditions & facilities</label>
                    <ReactStars
                        {...starProps}
                        onChange={handleRatingChange('propertyCondition')}
                        value={formData.propertyCondition}
                    />
                </div>

                <div className="rating-group">
                    <label>Area around the property</label>
                    <ReactStars
                        {...starProps}
                        onChange={handleRatingChange('areaRating')}
                        value={formData.areaRating}
                    />
                </div>
            </div>

            <div className="form-group">
                <label>Review Title</label>
                <input
                    type="text"
                    name="reviewTitle"
                    value={formData.reviewTitle}
                    onChange={handleInputChange}
                />
            </div>

            <div className="form-group">
                <label>Review Content</label>
                <textarea
                    name="reviewContent"
                    value={formData.reviewContent}
                    onChange={handleInputChange}
                    rows={6}
                />
            </div>

            {error && (
                <div className="error-message" style={{ color: 'red', marginBottom: '15px' }}>
                    {error}
                </div>
            )}

            <button type="submit" className="btn btn-secondary text-primary font-weight-bold rounded" disabled={submitting}>
                {submitting ? 'Submitting...' : 'Submit'}
            </button>
        </form>
    );
};

export default ShareReviewsForm;
