import React, { useState } from 'react';
import { Link } from 'gatsby';
import Cookies from 'js-cookie';
import styles from './styles.module.scss';
import { getSiteMeta } from '../../util/providers';
import AddressDisplay from './components/AddressDisplay';
import ContactUs from '../ContactUs';
import Modal from '../Modal';
import WithLocation from '../WithLocation';
import { formatPhoneNumber } from '../../util/helpers';

const Footer = ({ location, search }) => {
  const siteMeta = getSiteMeta();
  const [contactUsModalIsOpen, setContactUsModalIsOpen] = useState(false);

  const nodisc = search.nodisc == '1' ? 1 : Cookies.get('nodisc');
  return (
    <footer className={`${styles.container} container mt-5`}>
      <div className={`${styles.innerContainer} container pt-4 pb-3`}>
        {/* Social Media Icons Row */}
        <div className="row mb-3">
          <div className="col-12 text-center">
            <a
              target="_blank"
              href={`https://www.facebook.com/${siteMeta.primaryFacebook}`}
              title="Facebook"
              className="mx-2 mb-2"
            >
              <img className={`${styles.socialLink} mb-2`} src="/facebook.svg" height="40" width="30" alt="Facebook" />
            </a>
            <a
              target="_blank"
              href={`https://twitter.com/${siteMeta.primaryTwitter}`}
              title="Twitter"
              className="mx-2 mb-2"
            >
              <img
                src="/twitter.svg"
                alt="Twitter"
                className={`${styles.socialLink} mb-2`}
                height="40"
                width="30"
              />
            </a>
            <a
              target="_blank"
              href={`https://www.instagram.com/${siteMeta.primaryInstagram}`}
              title="Instagram"
              className="mx-2 mb-2"
            >
              <img
                className={`${styles.socialLink} mb-2`}
                alt="Instagram"
                src="/instagram.svg"
                height="40"
                width="30"
              />
            </a>
            <a
              target="_blank"
              title="Youtube"
              href={`https://www.youtube.com/c/${siteMeta.primaryYoutube}`}
              className="mx-2 mb-2"
            >
              <img
                className={`${styles.socialLink} mb-2`}
                alt="Youtube"
                src="/youtube.svg"
                height="40"
                width="30"
              />
            </a>
            <a
              target="_blank"
              href="https://www.pinterest.com/BeachHousesInParadise/"
              title="Pinterist"
              className="mx-2 mb-2"
            >
              <img
                className={`${styles.socialLink} mb-2`}
                alt="Pinterist"
                src="/pinterest.svg"
                height="40"
                width="30"
              />
            </a>
            <a
              target="_blank"
              href="https://g.page/BeachHousesinParadise"
              title="Google My Business"
              className="mx-2 mb-2"
            >
              <img
                className={`${styles.socialLink} mb-2`}
                src="/google-my-business.svg"
                alt="Google My Business"
                height="40"
                width="30"
              />
            </a>
            <a
              target="_blank"
              href="https://www.yelp.com/biz/beach-houses-in-paradise-stuart"
              title="Yelp"
              className="mx-2 mb-2"
            >
              <img
                className={`${styles.socialLink} mb-2`}
                alt="Yelp"
                src="/yelp.svg"
                height="40"
                width="30"
              />
            </a>
          </div>
        </div>

        {/* Links Row */}
        <div className="row mb-3">
          <div className="col-12 text-center">
            <div className="d-inline-block mx-2 mb-2">
              <span>BHIP &#169; {new Date().getFullYear()}</span>
            </div>
            <div className="d-inline-block mx-2 mb-2">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
            <div className="d-inline-block mx-2 mb-2">
              <Link to="/hurricane-100-refund-policy">Hurricane Policy</Link>
            </div>
            <div className="d-inline-block mx-2 mb-2">
              <Link to="/terms">Terms</Link>
            </div>
            <div className="d-inline-block mx-2 mb-2">
              <a
                style={{ cursor: 'pointer' }}
                className="btn btn-link text-primary p-0"
                onClick={() => setContactUsModalIsOpen(true)}
                to="/contact"
              >
                Contact Us
              </a>
            </div>
            <div className="d-inline-block mx-2 mb-2">
              <Link to="/faq">FAQ</Link>
            </div>
            <div className="d-inline-block mx-2 mb-2">
              <Link to="/discount-programs">Top 10 Deals and Discounts</Link>
            </div>
            <div className="d-inline-block mx-2 mb-2">
              <Link to="/share-experience">Share Your Experience</Link>
            </div>
          </div>
        </div>

        {/* Address Row */}
        <div className="row mb-3">
          <div className="col-12 text-center">
            <div>
              <AddressDisplay siteMeta={siteMeta} />
              <span >{formatPhoneNumber(siteMeta.primaryPhone)}</span>
            </div>
          </div>
        </div>
        {/* Legal Notice Row */}
        {nodisc != 1 && (
          <div className="row">
            <div className="col-12 text-left">
              <p className="text-muted text-small">
                <i className="small">
                  Legal notice: The number of bedrooms for our home listings may
                  vary from data presented in conventional real-estate listings,
                  and data on file with respect to each home's original
                  construction. Extra sleep rooms may be based upon bonus rooms,
                  lofts, wallbeds, sofabeds and/or other open or non-permanent
                  fixtures. The number of bathrooms may also differ, based on the
                  installation of outdoor showers, pet-washing facilities &
                  similar amenities.
                </i>
              </p>
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={contactUsModalIsOpen}
        title={'Beach House Rentals'}
        onClose={() => setContactUsModalIsOpen(false)}
      >
        <ContactUs />
      </Modal>
    </footer>
  );
};

export default WithLocation(Footer);
